import { ONE_HOUR, ONE_MINUTE } from '@js/constants'

export function capitalize(str) {
  if (str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
  }
  return str
}

export function formatDate(date, options) {
  date = new Date(date)
  options = {
    yearFirst: true,
    zeroPadded: true,
    separator: '-',
    ...(options || {})
  }

  const formattedDate = date.getDate().toString().padStart(options.zeroPadded ? 2 : 0, 0)
  const formattedMonth = (date.getMonth() + 1).toString().padStart(options.zeroPadded ? 2 : 0, 0)

  if (options.yearFirst) {
    return `${date.getFullYear()}-${formattedMonth}-${formattedDate}`
  } else {
    return [formattedDate, formattedMonth, date.getFullYear()].join(options.separator)
  }
}

export function secondsFromStartOfDay(dateTime) {
  const startOfDay = new Date(dateTime).setHours(0, 0, 0, 0)
  return (new Date(dateTime).getTime() - startOfDay) / 1000
}

export function secondsTimeRangeForSelector(dateTime) {
  const dateTimeInSeconds = Number.isInteger(dateTime) ? dateTime : secondsFromStartOfDay(dateTime)
  const startTime = Math.max(dateTimeInSeconds - 10800, 28800)
  const endTime = Math.min(dateTimeInSeconds + 10800, 77400)
  return `${startTime}-${endTime}`
}

export function formatTime(seconds) {
  const hour = Math.floor(seconds / ONE_HOUR)
  const minutes = seconds % ONE_HOUR / ONE_MINUTE
  return formatTimeByFromHour(hour, minutes)
}

export function formatTimeFromDate(date) {
  date = new Date(date)
  const hour = date.getHours()
  const minutes = date.getMinutes()
  return formatTimeByFromHour(hour, minutes)
}

function formatTimeByFromHour(hour, minutes) {
  // 12-hour format
  const suffix = hour >= 12 ? 'PM' : 'AM'
  hour = (hour - 1) % 12 + 1
  minutes = minutes.toString().padStart(2, 0)
  return `${hour}:${minutes}${suffix}`
}

export function isSystemAccount(user) {
  const email = user.email.toLowerCase()
  return email.includes('3p_logistics@grain.com.sg') || email.includes('hub_self_pickup@grain.com.sg')
}
