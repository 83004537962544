<template>
  <div class="login-form">
    <slot/>
    <form @submit.prevent="login">
      <b-field :label="$t('login.emailLabel')">
        <b-input
          v-model="email"
          :autofocus="true"
          name="email"
          autocomplete="email"
          type="email"/>
      </b-field>
      <b-field :label="$t('login.passwordLabel')">
        <b-input
          v-model="password"
          name="password"
          autocomplete="current-password"
          type="password"/>
      </b-field>
      <div
        v-if="error"
        class="error-message">
        {{ error }}
      </div>
      <b-button
        tag="input"
        nativeType="submit"
        :value="$t('login.loginLabel')"
        :disabled="isLoggingIn"
        class="is-primary"
        expanded/>
    </form>
    <a
      class="forgot-password"
      @click="forgotPassword">
      {{ $t('login.forgotPasswordLabel') }}
    </a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@js/utils'
import cookies from '@js/cookies'

export default {
  name: 'login-form',
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getErrorMessage'
    ]),
    ...mapGetters('sessionUser', [
      'isLoggedIn'
    ]),
    error() {
      const message = this.getErrorMessage('sessionUser/login')
      if (message && typeof message === 'string') {
        return capitalize(message)
      }
      return null
    }
  },
  watch: {
    error() {
      if (this.error) {
        this.isLoggingIn = false
      }
    },
    isLoggedIn: {
      immediate: true,
      handler() {
        if (this.isLoggedIn) {
          this.redirect()
        }
      }
    }
  },
  methods: {
    ...mapActions('sessionUser', [
      'loginRequest'
    ]),
    login() {
      if (this.email && this.password) {
        this.isLoggingIn = true
        this.loginRequest({
          email: this.email,
          password: this.password
        })
      }
    },
    forgotPassword() {
      this.$buefy.dialog.alert({
        message: 'Coming soon',
        hasIcon: true,
        icon: 'info-circle'
      })
    },
    redirect() {
      let redirectPath = '/'
      const key = `${window.location.host}/redirectPath`
      try {
        redirectPath = window.sessionStorage.getItem(key) || '/'
        window.sessionStorage.removeItem(key)
      } catch(_) {
        redirectPath = cookies.getCookie(key)
        cookies.deleteCookie(key)
      }
      this.$router.push(redirectPath)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  width: 100%;
  max-width: 320px;
  margin-right: auto;
  margin-left: auto;

  form {
    [type="submit"] {
      margin-top: $space-l;
    }

    .error-message {
      color: $red;

      & + [type="submit"] {
        margin-top: $space-m;
      }
    }
  }

  .forgot-password {
    display: block;
    margin-top: $space-m;
    text-align: center;
  }
}
</style>
